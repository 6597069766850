import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import bootstrap from "./plugins/bootstrap";
import axios from "./plugins/axios";
import vuevalidate from "./plugins/vuevalidate";
import FontAwesomeIcon from "./plugins/fontawesome";
import AxiosPlugin from "./plugins/axios_cors";
import ToastPlugin from "./plugins/toast";
import VueApexCharts from "./plugins/apexcharts";
import VueGoogleMaps from "./plugins/gmap";
import JsonExcel from "./plugins/excel";
import VueCryptojs from "./plugins/vuecrypto";
import VueI18n from "./plugins/i18n";
import messages from "./lang";

require("@/assets/css/style.scss");

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;
if (localStorage.getItem("lang") != null) {
  var lang = localStorage.getItem("lang");
} else {
  localStorage.setItem("lang", "es");
  lang = localStorage.getItem("lang");
}

export const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: "es",
  messages,
});

new Vue({
  i18n,
  JsonExcel,
  VueGoogleMaps,
  router,
  VueApexCharts,
  VueCryptojs,
  store,
  vuetify,
  vuevalidate,
  bootstrap,
  axios,
  AxiosPlugin,
  ToastPlugin,
  components: { App },
  render: (h) => h(App),
}).$mount("#app");
